<template>
    <circle class="primary" opacity="0.05" cx="186.7" cy="117.2" r="71.8"/>
    <path d="m39.2 237.3c-3.4 0-6.1-2.7-6.1-6.1s2.7-6.1 6.1-6.1 6.1 2.7 6.1 6.1-2.7 6.1-6.1 6.1zm0-9.9c-2.1 0-3.8 1.7-3.8 3.8s1.7 3.8 3.8 3.8 3.8-1.7 3.8-3.8-1.7-3.8-3.8-3.8z"/>
    <path d="m19.1 127.6c-4.2 0-7.6-3.4-7.6-7.6s3.4-7.6 7.6-7.6 7.6 3.4 7.6 7.6c0.1 4.2-3.3 7.6-7.6 7.6zm0-13c-3 0-5.4 2.4-5.4 5.4s2.4 5.4 5.4 5.4 5.4-2.4 5.4-5.4-2.4-5.4-5.4-5.4z"/>
    <path d="m292.9 206.8h-12.8c-0.8 0-1.5-0.7-1.5-1.5s0.7-1.5 1.5-1.5h12.8c0.8 0 1.5 0.7 1.5 1.5 0 0.9-0.7 1.5-1.5 1.5z"/>
    <path d="m53.7 72.2h-4.8v-4.8c0-0.6-0.5-1.1-1.1-1.1s-1.1 0.5-1.1 1.1v4.8h-4.8c-0.6 0-1.1 0.5-1.1 1.1s0.5 1.1 1.1 1.1h4.8v4.8c0 0.6 0.5 1.1 1.1 1.1s1.1-0.5 1.1-1.1v-4.8h4.8c0.6 0 1.1-0.5 1.1-1.1s-0.5-1.1-1.1-1.1z"/>
    <path d="m328.7 149.3h-6.6v-6.6c0-0.6-0.5-1.1-1.1-1.1s-1.1 0.5-1.1 1.1v6.6h-6.6c-0.6 0-1.1 0.5-1.1 1.1s0.5 1.1 1.1 1.1h6.6v6.6c0 0.6 0.5 1.1 1.1 1.1s1.1-0.5 1.1-1.1v-6.6h6.6c0.6 0 1.1-0.5 1.1-1.1s-0.5-1.1-1.1-1.1z"/>
    <path class="primary" d="m326.3 76.2c-0.1 2.8-2.4 4.9-5.2 4.8s-4.9-2.4-4.8-5.2 2.4-4.9 5.2-4.8c2.8 0.2 4.9 2.5 4.8 5.2z"/>
    <path class="primary" d="m61.3 177.5c-0.1 3-2.6 5.3-5.6 5.1s-5.3-2.6-5.1-5.6c0.1-3 2.6-5.3 5.6-5.1 2.9 0.1 5.2 2.6 5.1 5.6z"/>
    <path class="primary" d="m10.5 185.7c-0.1 2.9-2.6 5.2-5.5 5-2.9-0.1-5.2-2.6-5-5.5 0.1-2.9 2.6-5.2 5.5-5s5.1 2.6 5 5.5z"/>
    <path class="primary" d="m28.3 199.4c-0.1 2.2-2 4-4.2 3.9s-4-2-3.9-4.2 2-4 4.2-3.9c2.3 0 4 1.9 3.9 4.2z"/>
    <path class="primary" d="m273 85.3c-0.1 2.5-2.2 4.5-4.8 4.4-2.5-0.1-4.5-2.2-4.4-4.8 0.1-2.5 2.2-4.5 4.8-4.4 2.5 0.1 4.5 2.2 4.4 4.8z"/>
    <path class="primary" d="m292.6 101.6c-0.1 2.6-2.3 4.6-4.9 4.5s-4.6-2.3-4.5-4.9 2.3-4.6 4.9-4.5 4.6 2.3 4.5 4.9z"/>
    <path class="primary" d="m198 82.5c-0.8 0-1.5-0.7-1.5-1.5v-21.8c0-0.8 0.7-1.5 1.5-1.5s1.5 0.7 1.5 1.5v21.8c0 0.8-0.7 1.5-1.5 1.5z"/>
    <path class="primary" d="m173.9 91.6c-0.8 0-1.5-0.7-1.5-1.5v-9c0-0.8 0.7-1.5 1.5-1.5s1.5 0.7 1.5 1.5v9c0 0.8-0.7 1.5-1.5 1.5z"/>
    <path class="primary" d="m173.1 16.6c0.8 0 1.5-0.7 1.5-1.5v-13.6c0-0.8-0.7-1.5-1.5-1.5s-1.5 0.7-1.5 1.5v13.6c0 0.8 0.7 1.5 1.5 1.5z"/>
    <path d="m248.9 62.4-7.3-7.3 7.3-7.3 7.3 7.3-7.3 7.3zm-4.7-7.3 4.7 4.7 4.7-4.7-4.7-4.7-4.7 4.7z"/>
    <path class="primary" d="m167.1 93.6c-2.9-1.2-6-1.8-9.1-1.8-12.9 0-23.4 10.5-23.4 23.4 0 3.3 0.7 6.5 2 9.5 0.3 0.8 1.2 1.1 2 0.8l39-0.4c0.8 0.3 1.7 0 2-0.8 5-11.9-0.6-25.7-12.5-30.7zm7.8 21c-0.1 0.8-0.7 1.4-1.6 1.4h-0.1c-0.9-0.1-1.5-0.8-1.4-1.7 0.6-7.2-4.8-13.5-12-14.1-1.9-0.1-3.7 0.1-5.5 0.7-0.8 0.3-1.7-0.1-2-0.9s0.1-1.7 0.9-2c8.4-3 17.7 1.3 20.8 9.8 0.8 2.2 1.1 4.5 0.9 6.8z"/>
    <path d="m188.9 127h-58.8c-0.8 0-1.5-0.7-1.5-1.5s0.7-1.5 1.5-1.5h58.8c0.8 0 1.5 0.7 1.5 1.5s-0.6 1.5-1.5 1.5z"/>
    <path d="m221.3 92.6c0-0.8-0.7-1.5-1.6-1.4-12 0.5-21.5 8.3-21.8 17.7h-9.8c-0.8 0-1.5 0.7-1.5 1.5s0.7 1.5 1.5 1.5h9.8 0.2c0.7 3.8 2.6 7.3 5.4 10 0.3 0.3 0.6 0.4 1 0.4s0.8-0.2 1.1-0.5c0.6-0.6 0.5-1.6-0.1-2.1-2.9-2.7-4.6-6.4-4.7-10.3 0-7.6 7.5-14 17.4-15v21.4c0 0.8 0.7 1.5 1.5 1.5s1.5-0.7 1.5-1.5l0.1-23.2v0z"/>
    <path d="m226.1 139.4c0 2-1.6 3.7-3.7 3.7s-3.7-1.6-3.7-3.7 1.6-3.7 3.7-3.7 3.7 1.7 3.7 3.7z"/>
    <path d="m215.5 134.8-2.5-1.7c3.4-5.2 10.4-6.6 15.6-3.2l-1.7 2.5c-3.8-2.4-8.9-1.4-11.4 2.4z"/>
    <path class="primary" d="m70.8 188.7c-0.3 0-0.6-0.1-0.8-0.2l-6.7-4.4c-0.7-0.5-0.9-1.4-0.4-2.1s1.4-0.9 2.1-0.4l6.7 4.4c0.7 0.5 0.9 1.4 0.4 2.1-0.3 0.3-0.8 0.6-1.3 0.6z"/>
    <path class="primary" d="m32.6 195.2c-0.8 0-1.5-0.7-1.5-1.5 0-0.5 0.2-0.9 0.6-1.2l14.1-11.2c0.7-0.5 1.6-0.4 2.1 0.2s0.4 1.6-0.2 2.1l-14.1 11.2c-0.3 0.3-0.6 0.4-1 0.4z"/>
    <path class="primary" d="m18.2 196.7c-0.3 0-0.7-0.1-1-0.3l-5.1-4.1c-0.7-0.5-0.8-1.4-0.3-2.1s1.4-0.8 2.1-0.3c0 0 0.1 0 0.1 0.1l5.1 4.1c0.6 0.5 0.7 1.5 0.2 2.1-0.2 0.3-0.6 0.5-1.1 0.5z"/>
    <path class="primary" d="m294.8 98.7c-0.8 0-1.5-0.7-1.5-1.5 0-0.5 0.2-0.9 0.6-1.2l20.5-16c0.7-0.5 1.6-0.4 2.1 0.3s0.4 1.6-0.3 2.1l-20.5 16c-0.2 0.2-0.6 0.3-0.9 0.3z"/>
    <path class="primary" d="m280.9 97.1c-0.4 0-0.8-0.2-1.1-0.5l-5.3-5.5c-0.6-0.6-0.6-1.6 0-2.1 0.6-0.6 1.6-0.6 2.1 0l5.3 5.5c0.6 0.6 0.6 1.6 0 2.1-0.3 0.3-0.6 0.5-1 0.5z"/>
    <path class="primary" d="m243.7 103.6c-0.8 0-1.5-0.7-1.5-1.5 0-0.5 0.2-1 0.6-1.2l17.6-12.4c0.7-0.5 1.6-0.3 2.1 0.4s0.3 1.6-0.4 2.1l-17.6 12.4c-0.2 0.1-0.5 0.2-0.8 0.2z"/>
    <path d="m271.3 141.1c-4.4-0.6-12.1-2.1-14-3.9-3-2.7-5.6-5.5-8.1-8.2-6.1-6.5-11.8-12.7-21.2-16.3-0.8-0.3-1.6 0.1-1.9 0.9s0.1 1.6 0.8 1.9c8.7 3.4 14 9 20.1 15.6 2.6 2.7 5.2 5.6 8.3 8.4 2.7 2.5 11 3.9 14.3 4.4v29.7c-3.3 0.6-11.6 2.1-14.2 4-5 3.7-8 7.8-11 11.8-3.5 4.7-6.8 9.1-12.9 12.2l-0.1 0.1c-3.3 2.1-6.9 3.5-10.7 4.4l-0.8-3.7c-0.2-0.8-1-1.3-1.8-1.1s-1.3 1-1.1 1.8l5.5 24.1h-20.4l-5.6-25c-0.2-0.8-1-1.3-1.8-1.1s-1.3 1-1.1 1.8l0.9 4h-13.8c-0.3-0.5-0.9-0.8-1.6-0.7-0.4 0.1-0.8 0.3-1 0.7h-39.1l0.8-4c0.2-0.8-0.4-1.6-1.2-1.8s-1.6 0.4-1.8 1.2l-4.7 24.9h-20.4l4.6-24.1c0.2-0.8-0.4-1.6-1.2-1.8s-1.6 0.4-1.8 1.2l-0.4 2c-15.1-5.5-30.3-20.4-30.3-41.7v-1.5c0-29.1 21-49.4 39.9-49.4h4.6c0.8 0 1.5-0.7 1.5-1.5s-0.7-1.5-1.5-1.5h-4.6c-12.9 0-26.8 8.7-35.2 23.1h-0.1c-4.7-0.2-8.1-1.6-10.3-4.1-1.9-2.3-2.8-5.2-2.7-8.1 0.1-0.8-0.5-1.5-1.3-1.6s-1.6 0.5-1.7 1.3c-0.2 3.8 1 7.5 3.4 10.4 2.5 2.9 6.3 4.6 11.2 5-3.8 7.6-6.2 16.5-6.2 26.5v1.5c0 13 5.6 25.5 15.3 34.2 5 4.7 11 8.2 17.4 10.5l-3.9 20.9v0.3c0 0.8 0.7 1.5 1.5 1.5h23.5c0.7 0 1.3-0.5 1.5-1.2l3.6-19.1h12.5l-3 16.6h-8.1c-0.8 0-1.5 0.7-1.5 1.5s0.7 1.5 1.5 1.5h9.4c0.7 0 1.4-0.5 1.5-1.2l3.3-18.4h24l3.8 19.1c0.1 0.7 0.8 1.2 1.5 1.2h9.4c0.8 0 1.5-0.7 1.5-1.5s-0.7-1.5-1.5-1.5h-8.1l-3.5-17.3h13.8l4.3 19.2c0.2 0.7 0.8 1.2 1.5 1.2h23.5 0.3c0.8-0.2 1.3-1 1.1-1.8l-4.4-19.3c4.1-0.9 8-2.5 11.6-4.7 6.7-3.4 10.3-8.3 13.9-13.1 2.9-3.8 5.8-7.8 10.4-11.2 1.9-1.4 9.7-3 14.1-3.7l1.3-0.2v-34.9l-1.3-0.4z"/>
    <path d="m263.5 154.9c-0.8 0-1.5-0.7-1.5-1.5v-8.3c0-0.8 0.7-1.5 1.5-1.5s1.5 0.7 1.5 1.5v8.3c0 0.8-0.6 1.5-1.5 1.5z"/>
    <path class="primary" d="m189 39.2c-0.1-6.1-2.9-11.5-7.2-15.1v-11.3c0-0.8-0.7-1.5-1.5-1.5s-1.5 0.7-1.5 1.5v9.3c-2.9-1.6-6.2-2.5-9.7-2.5-10.9 0.1-19.7 9-19.6 20 0.1 10.9 9 19.7 20 19.6 10.9-0.1 19.6-8.9 19.6-19.8-0.1-0.1-0.1-0.1-0.1-0.2zm-10.8-10c-0.4 0.7-1.3 0.9-2 0.5-2.8-1.7-6.3-2.2-9.5-1.3-6.4 1.8-10.2 8.4-8.4 14.9 0.2 0.8-0.2 1.6-1 1.8s-1.6-0.2-1.8-1c-0.5-1.9-0.7-3.9-0.4-5.9 0.8-6 5.1-11 10.9-12.6 4-1.1 8.2-0.5 11.8 1.6 0.5 0.4 0.8 1.3 0.4 2z"/>
    <path class="primary" d="m129.8 51.6c-1.2 0-2.3 0.1-3.4 0.3v-10.8c0-0.8-0.7-1.5-1.5-1.5s-1.5 0.7-1.5 1.5v11.5c-1.6 0.5-3.1 1.2-4.5 2.1v-20.9c0-0.8-0.7-1.5-1.5-1.5s-1.5 0.7-1.5 1.5v22.6c0 0.1 0 0.3 0.1 0.4-4.5 3.9-7.4 9.6-7.4 16.1 0 11.7 9.5 21.3 21.3 21.3 11.7 0 21.2-9.5 21.3-21.3-0.2-11.8-9.7-21.3-21.4-21.3zm10.5 10.6c-0.5 0.7-1.4 0.8-2.1 0.4-3.1-2.2-7-3-10.7-2.2-7.5 1.6-12.3 8.9-10.7 16.4 0.2 0.8-0.4 1.6-1.2 1.8h-0.3c-0.7 0-1.3-0.5-1.5-1.2-1.9-9.1 3.9-18 13-20 4.5-1 9.3 0 13.1 2.6 0.8 0.6 0.9 1.5 0.4 2.2z"/>
</template>

<script>
export default {
    setup(props, { emit }) {
        emit('dimensions', { width: 329.8, height: 237.3 });
    },
    emits: ['dimensions'],
};
</script>
